import {updateState} from '../../utils/updateState.js';

const initialState = {
  dashboard: null,
  dashboardComplianceRate: null,
  dashboardTotalCompliance: null,
  dashboardComplianceStatus: null,
  dashboardComplianceStatusComply: null,
  dashboardComplianceStatusNotComply: null,
  dashboardComplianceTopComply: null,
  dashboardComplianceTopNotComply: null,
  dashboardComplianceStatistics: [],
  dashboardComplianceDivisionStatistics: [],
  dashboardGroup: null,
  searchDashboardGroupResult: null
};

const setDataDashboard = (state, action) => {
  return updateState(state, {
    dashboard: action.payload
  })
}

const setDashboardComplianceStatus = (state, action) => {
  switch (action.category) {
    case 'status-comply':
      return updateState(state, {
        dashboardComplianceStatusComply: action.payload
      })
    case 'status-not-comply':
      return updateState(state, {
        dashboardComplianceStatusNotComply: action.payload
      })
    case 'top-comply':
      return updateState(state, {
        dashboardComplianceTopComply: action.payload.data
      })
    case 'top-not-comply':
      return updateState(state, {
        dashboardComplianceTopNotComply: action.payload.data
      })
    case 'rate':
      return updateState(state, {
        dashboardComplianceRate: action.payload,
        dashboardTotalCompliance: action.payload.total
      })
    case 'status-all':
      return updateState(state, {
        dashboardComplianceDivisionStatistics: action.payload
      })
    default:
      return updateState(state, {
        dashboardComplianceStatus: action.payload
      })
  }
}

const setDashboardComplianceStatistics = (state, action) => {
  switch (action.category) {
    // case 'division':
    //   return updateState(state, {
    //     dashboardComplianceDivisionStatistics: action.payload
    //   })
    case 'time':
      return updateState(state, {
        dashboardComplianceStatistics: action.payload
      })
    default:
      break;
  }
}

const setDashboardGroup = (state, action) => {
  return updateState(state, {
    dashboardGroup: action.payload
  })
}

const setSearchDashboardGroup = (state, action) => {
  return updateState(state, {
    searchDashboardGroupResult: action.payload
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA_DASHBOARD': return setDataDashboard(state, action);
    case 'SET_DASHBOARD_COMPLIANCE_STATUS': return setDashboardComplianceStatus(state, action);
    case 'SET_DASHBOARD_COMPLIANCE_STATISTICS': return setDashboardComplianceStatistics(state, action);
    case 'SET_DASHBOARD_GROUP': return setDashboardGroup(state, action);
    case 'SET_SEARCH_DASHBOARD_GROUP': return setSearchDashboardGroup(state, action);
    default:
      return state;
  }
};

export default reducer;
