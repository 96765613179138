import { put } from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import ApiBase from "../../ApiBase";
import jwt from "jsonwebtoken";
import * as actions from "../../actions";
import moment from "moment";

export function* getListCompliance(action) {
  const url = ApiBase.getUrl() + `/v2/compliance/list`;
  const params = { ...action.params };
  try {
    yield put(actions.setLoader(true));
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setLoader(false));
    yield put(actions.setListCompliance(data));
    yield put(actions.setComplianceTotalData(data.total_data));
  } catch (error) {
    yield put(actions.setLoader(false));
    console.log(error.response);
  }
}

export function* getListComplianceDashboard(action) {
  const url = ApiBase.getUrl() + `/v2/compliance/list-dashboard`;
  const params = { ...action.params };
  try {
    yield put(actions.setLoader(true));
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setLoader(false));
    yield put(actions.setListCompliance(data));
  } catch (error) {
    yield put(actions.setLoader(false));
    console.log(error.response);
  }
}


export function* getFilterCompliance(action) {
  const params = action.params;
  const url = ApiBase.getUrl() + `/v2/compliance/list-filter`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setFilterCompliance(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* getDetailCompliance(action) {
  yield put(actions.setDetailCompliance({}));
  yield put(actions.setResponseCompliance(null, null));
  const id = action.id;
  const params = action.params;
  const url = ApiBase.getUrl() + `/compliance/${id}/?t=${moment().unix()}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setDetailCompliance(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* assignPICtoCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/assign";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.setResponseCompliance(response.data, "assign_pic"));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, "assign_pic"));
  }
}

export function* bulkAssignToCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/assign";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.setResponseCompliance(response.data, "assign_pic"));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, "assign_pic"));
  }
}

export function* updateDetailCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/update";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(
      actions.setResponseCompliance(response.data, "update_compliance")
    );
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "update_compliance")
    );
  }
}

export function* getListRoleCompliance(action) {
  const token = localStorage.getItem("token");
  const { company_uuid } = jwt.decode(token);
  const params = action.params;
  const url = ApiBase.getUrl() + `/member/role/list/${company_uuid}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setListRoleCompliance(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* addRoleCompliance(action) {
  const token = localStorage.getItem("token");
  const { company_uuid } = jwt.decode(token);
  const url = ApiBase.getUrl() + "/member/role/create";
  const data = { ...action.data, company_uuid: company_uuid };
  try {
    let response = yield axios.post(url, data, {
      headers: {
        access_token: localStorage.getItem("token")
      }
    });
    yield put(actions.setResponseCompliance(response.data, "add_role"));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, "add_role"));
  }
}

export function* deleteRoleCompliance(action) {
  const url = ApiBase.getUrl() + "/member/role/delete";
  const data = action.data;

  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data,
      params: {
        lang: data.lang
      }
    });
    yield put(actions.setResponseCompliance(response.data, "delete_role"));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "delete_role")
    );
  }
}

export function* getListObligationAction(action) {
  const params = action.params;
  const url = ApiBase.getUrl() + `/member/obligation-action/list`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setListObligationAction(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* getDetailRoleCompliance(action) {
  const id = action.id;
  const params = action.params;
  const url = ApiBase.getUrl() + `/member/role/detail/${id}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setDetailRoleCompliance(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* updateDetailRoleCompliance(action) {
  const url = ApiBase.getUrl() + "/member/role/update";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(
      actions.setResponseCompliance(response.data, "update_role_compliance")
    );
  } catch (error) {
    yield put(
      actions.setResponseCompliance(
        error.response.data,
        "update_role_compliance"
      )
    );
  }
}

export function* addMemberCompliance(action) {
  const token = localStorage.getItem("token");
  const { company_uuid } = jwt.decode(token);
  const url = ApiBase.getUrl() + "/member/create";
  let data = { ...action.data, company_uuid: company_uuid };
  try {
    let response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.setResponseCompliance(response.data, "add_member"));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, "add_member"));
  }
}

export function* getListMemberCompliance(action) {
  const token = localStorage.getItem("token");
  const { company_uuid } = jwt.decode(token);
  const params = { ...action.params, company: company_uuid };
  const url = ApiBase.getUrl() + `/member/list`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setListMemberCompliance(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* updateDetailMemberCompliance(action) {
  const url = ApiBase.getUrl() + "/member/update";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.setResponseCompliance(response.data, "update_member"));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "update_member")
    );
  }
}

export function* updateProcessCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/process/update";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(
      actions.setResponseCompliance(response.data, "update_process_compliance")
    );
  } catch (error) {
    yield put(
      actions.setResponseCompliance(
        error.response.data,
        "update_process_compliance"
      )
    );
  }
}

export function* updateStatusCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/status/update";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(
      actions.setResponseCompliance(response.data, "update_status_compliance")
    );
  } catch (error) {
    yield put(
      actions.setResponseCompliance(
        error.response.data,
        "update_status_compliance"
      )
    );
  }
}

export function* createChecklistCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/checklist/create";
  let data = action.data;

  try {
    yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.getDetailCompliance(data.comp_uuid));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "create_checklist")
    );
  }
}

export function* getDetailChecklistCompliance(action) {
  const id = action.id;
  const params = action.params;
  const url = ApiBase.getUrl() + `/compliance/checklist/${id}`;
  try {
    const response = yield axios.get(url, {
      headers: {
        access_token: localStorage.getItem("token")
      },
      params
    });
    const data = response.data.data;
    yield put(actions.setDetailChecklistCompliance(data));
  } catch (error) {
    console.log(error.response);
  }
}

export function* updateChecklistCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/checklist/update";
  let data = action.data;
  delete data.due_date
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    if (data.isClaimChecklist) {
      yield put(actions.getDetailCompliance(data.comp_uuid));
      yield put(actions.setActivityList(data.comp_uuid));
    } else {
      yield put(
        actions.setResponseCompliance(response.data, "update_checklist")
      );
    }
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "update_checklist")
    );
  }
}

export function* deleteChecklistCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/checklist/delete";
  const data = action.data;

  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data
    });
    yield put(actions.setResponseCompliance(response.data, "delete_checklist"));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "delete_checklist")
    );
  }
}

export function* bookmarkCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/bookmark";
  let data = {
    compliance_uuid: action.id
  };

  try {
    yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.getDetailCompliance(action.id));
  } catch (error) {
    console.log("bookmark error", error);
  }
}

export function* removeMemberCompliance(action) {
  const url = ApiBase.getUrl() + "/member/delete";
  const data = action.data;
  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data
    });
    yield put(actions.setResponseCompliance(response.data, "delete_member"));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "delete_member")
    );
  }
}

export function* renewalCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/renewal";
  let data = action.data;
  try {
    const response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(
      actions.setResponseCompliance(response.data, "renewal_compliance")
    );
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "renewal_compliance")
    );
  }
}

export function* bulkActivationCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/bulk-activation";
  let data = action.data;
  try {
    let response = yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.setResponseCompliance(response.data, "bulk_activation"));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "bulk_activation")
    );
  }
}

export function* getComment(action) {
  const url = ApiBase.getUrl() + "/comments/list";
  const id = action.id;

  try {
    let response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
      params: {
        comp_uuid: id,
        orderBy: "created_at",
        order: "desc"
      }
    });

    yield put(actions.setComment(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* comment(action) {
  const url = ApiBase.getUrl() + "/comments";
  let data = action.data;
  const id = data.comp_uuid;

  try {
    yield axios.put(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });

    yield put(actions.getComment(id));
  } catch (err) {
    console.log(err.response.data);
  }
}

// eslint-disable-next-line require-yield
export function* deleteComment(action) {
  const url = ApiBase.getUrl() + "/comments";
  const id = action.commentId;
  const compId = action.complianceId;

  const data = {
    comment_uuid: id
  };

  try {
    const response = yield axios.delete(url, {
      headers: { access_token: localStorage.getItem("token") },
      data
    });

    yield put(actions.setResponseCompliance(response.data, "delete_comment"));
    yield put(actions.getComment(compId));
  } catch (err) {
    yield put(
      actions.setResponseCompliance(err.response.data, "delete_comment")
    );
  }
}

// eslint-disable-next-line require-yield
export function* editComment(action) {
  const url = ApiBase.getUrl() + "/comments";
  const data = action.data;
  const compId = action.complianceId;

  try {
    yield axios.patch(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });

    yield put(actions.getComment(compId));
  } catch (err) {
    console.log("Edit Error: ", err);
  }
}

export function* setReminder(action) {
  const url = ApiBase.getUrl() + "/reminder/create";
  let data = { ...action.data };

  try {
    let response = yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") }
    });
    yield put(actions.setResponseCompliance(response.data, "set_reminder"));
  } catch (error) {
    yield put(
      actions.setResponseCompliance(error.response.data, "set_reminder")
    );
  }
}

export function* getActivityList(action) {
  const url = ApiBase.getUrl() + `/activity-logs`;
  const compliance_uuid = action.id;
  const lang = localStorage.getItem("language")

  let params = {
    type:"compliance",
    type_uuid: compliance_uuid, 
    limit: 10,
    offset: 0,
    sort_by: 'created_at',
    sort_value: 'DESC',
    lang: lang
  }

  try {
    let response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
      params
    });

    yield put(actions.getActivityList(response.data.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getActivityListAll(action) {
  const lang = localStorage.getItem("language")
  let params = {
    ...action.params,
    type: 'all',
    sort_by: 'created_at',
    sort_value: 'DESC',
    lang: lang
  }

  const url = ApiBase.getUrl() + `/activity-logs`;

  try {
    let response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") },
      params
    });

    const {total_page, data} = response.data.data

    yield put(actions.getActivityListAllResponse(data, total_page));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* bulkAddMemberCompliance(action) {
  const url = ApiBase.getUrl() + "/member/assign-bulk";
  let data = action.data;
  try {
    let response =  yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setResponseCompliance(response.data, 'bulk_add_member'));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, "bulk_add_member"));
  }
}

export function* bulkDuplicateCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/duplicate";
  let data = action.data;
  try {
    let response =  yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setResponseCompliance(response.data, 'bulk_duplicate_compliance'));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, "bulk_duplicate_compliance"));
  }
}

export function* bulkDeleteCompliance(action){
  const url = ApiBase.getUrl() + "/v2/compliance/bulk-delete";
  const ids = { compliance_uuids: action.complianceIds }

  try {
    const response = yield axios.post(url, ids,{
      headers: { 'access_token': localStorage.getItem("token")},
    })

    yield put(actions.setResponseCompliance(response.data, 'bulk_delete_compliance'));
  } catch (error) {
    yield put(actions.setResponseCompliance(error.response.data, 'bulk_delete_compliance'));
  }
}

export function* confirmCompliance(action) {
  const url = ApiBase.getUrl() + "/compliance/confirm";
  yield put(actions.setLoadingConfirmCompliance(true));
  let data = action.data;
  try {
    yield axios.post(url, data, {
      headers: { access_token: localStorage.getItem("token") },
    });
    yield put(actions.setConfirmComplianceResponse('success'));
  } catch (error) {
    yield put(actions.setConfirmComplianceResponse('error'));
  } finally {
    yield put(actions.setLoadingConfirmCompliance(false));
  }
}

export function* getComplianceDetailChanges(action) {
  const url = ApiBase.getUrl() + `/compliance/${action.data.comp_uuid}/diffs`;

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") }
    });

    yield put(actions.setComplianceDetailChanges(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getComplianceMainInfoChanges(action) {
  const url = ApiBase.getUrl() + `/compliance/${action.comp_uuid}/diffs/information`;

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") }
    });

    yield put(actions.setComplianceMainInfoChanges(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getComplianceChecklistChanges(action) {
  const url = ApiBase.getUrl() + `/compliance/${action.comp_uuid}/diffs/checklist`;

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") }
    });

    yield put(actions.setComplianceChecklistChanges(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}

export function* getComplianceRegulationChanges(action) {
  const url = ApiBase.getUrl() + `/compliance/${action.comp_uuid}/diffs/regulation`;

  try {
    const response = yield axios.get(url, {
      headers: { access_token: localStorage.getItem("token") }
    });

    yield put(actions.setComplianceRegulationChanges(response.data.data));
  } catch (error) {
    console.log(error.response.data);
  }
}