export const getListCompliance = params => {
  return {
    type: "GET_LIST_COMPLIANCE",
    params
  };
};

export const getListComplianceDashboard = params => {
  return {
    type: "GET_LIST_COMPLIANCE_DASHBOARD",
    params
  };
};

export const setListCompliance = payload => {
  return {
    type: "SET_LIST_COMPLIANCE",
    payload
  };
};

export const getFilterCompliance = params => {
  return {
    type: "GET_FILTER_COMPLIANCE",
    params
  };
};

export const setFilterCompliance = payload => {
  return {
    type: "SET_FILTER_COMPLIANCE",
    payload
  };
};

export const getDetailCompliance = (id, params) => {
  return {
    type: "GET_DETAIL_COMPLIANCE",
    id,
    params
  };
};

export const setDetailCompliance = payload => {
  return {
    type: "SET_DETAIL_COMPLIANCE",
    payload
  };
};

export const assignPICtoCompliance = data => {
  return {
    type: "ASSIGN_PIC_TO_COMPLIANCE",
    data
  };
};

export const bulkAssignToCompliance = data => {
  return {
    type: "BUILK_ASSIGN_TO_COMPLIANCE",
    data
  };
};

export const setResponseCompliance = (response, responseOrigin) => {
  return {
    type: "SET_RESPONSE_COMPLIANCE",
    response,
    responseOrigin
  };
};

export const updateDetailCompliance = data => {
  return {
    type: "UPDATE_DETAIL_COMPLIANCE",
    data
  };
};

export const addMemberCompliance = data => {
  return {
    type: "ADD_MEMBER_COMPLIANCE",
    data
  };
};

export const getListMemberCompliance = params => {
  return {
    type: "GET_LIST_MEMBER_COMPLIANCE",
    params
  };
};

export const setListMemberCompliance = payload => {
  return {
    type: "SET_LIST_MEMBER_COMPLIANCE",
    payload
  };
};

export const updateDetailMemberCompliance = data => {
  return {
    type: "UPDATE_DETAIL_MEMBER_COMPLIANCE",
    data
  };
};

export const getListRoleCompliance = params => {
  return {
    type: "GET_LIST_ROLE_COMPLIANCE",
    params
  };
};

export const setListRoleCompliance = payload => {
  return {
    type: "SET_LIST_ROLE_COMPLIANCE",
    payload
  };
};

export const addRoleCompliance = data => {
  return {
    type: "ADD_ROLE_COMPLIANCE",
    data
  };
};

export const deleteRoleCompliance = data => {
  return {
    type: "DELETE_ROLE_COMPLIANCE",
    data
  };
};

export const getListObligationAction = params => {
  return {
    type: "GET_LIST_OBLIGATION_ACTION",
    params
  };
};

export const setListObligationAction = payload => {
  return {
    type: "SET_LIST_OBLIGATION_ACTION",
    payload
  };
};

export const getDetailRoleCompliance = (id, params) => {
  return {
    type: "GET_DETAIL_ROLE_COMPLIANCE",
    id,
    params
  };
};

export const setDetailRoleCompliance = payload => {
  return {
    type: "SET_DETAIL_ROLE_COMPLIANCE",
    payload
  };
};

export const updateDetailRoleCompliance = data => {
  return {
    type: "UPDATE_DETAIL_ROLE_COMPLIANCE",
    data
  };
};

export const updateProcessCompliance = data => {
  return {
    type: "UPDATE_PROCESS_COMPLIANCE",
    data
  };
};

export const updateStatusCompliance = data => {
  return {
    type: "UPDATE_STATUS_COMPLIANCE",
    data
  };
};

export const createChecklistCompliance = data => {
  return {
    type: "CREATE_CHECKLIST_COMPLIANCE",
    data
  };
};

export const getDetailChecklistCompliance = (id, params) => {
  return {
    type: "GET_DETAIL_CHECKLIST_COMPLIANCE",
    id,
    params
  };
};

export const setDetailChecklistCompliance = payload => {
  return {
    type: "SET_DETAIL_CHECKLIST_COMPLIANCE",
    payload
  };
};

export const updateChecklistCompliance = data => {
  return {
    type: "UPDATE_CHECKLIST_COMPLIANCE",
    data
  };
};

export const deleteChecklistCompliance = data => {
  return {
    type: "DELETE_CHECKLIST_COMPLIANCE",
    data
  };
};

export const bookmarkCompliance = id => {
  return {
    type: "BOOKMARK_COMPLIANCE",
    id
  };
};

export const removeMemberCompliance = data => {
  return {
    type: "REMOVE_MEMBER_COMPLIANCE",
    data
  };
};

export const renewalCompliance = data => {
  return {
    type: "RENEWAL_COMPLIANCE",
    data
  };
};

export const bulkActivationCompliance = data => {
  return {
    type: "BULK_ACTIVATION_COMPLIANCE",
    data
  };
};

export const getComment = id => {
  return {
    type: "GET_COMMENT",
    id
  };
};

export const setComment = data => {
  return {
    type: "SET_COMMENT",
    data
  };
};

export const comment = data => {
  return {
    type: "COMMENT",
    data
  };
};

export const deleteComment = (commentId, complianceId) => {
  return {
    type: "DELETE_COMMENT",
    commentId,
    complianceId
  };
};

export const editComment = (data, complianceId) => {
  return {
    type: "EDIT_COMMENT",
    data,
    complianceId
  };
};

export const setReminder = data => {
  return {
    type: "SET_REMINDER",
    data
  };
};

export const setActivityList = id => {
  return {
    type: "SET_ACTIVITY_LIST",
    id
  };
};

export const getActivityList = data => {
  return {
    type: "GET_ACTIVITY_LIST",
    data
  };
};

export const getActivityListAll = params => {
  return {
    type: "GET_ACTIVITY_LIST_ALL",
    params
  };
};

export const getActivityListAllResponse = (data,page_total) => {
  return {
    type: "GET_ACTIVITY_LIST_ALL_RESPONSE",
    data,
    page_total
  };
};


export const bulkAddMemberCompliance = (data) => {
  return {
    type: "BULK_ADD_MEMBER_COMPLIANCE",
    data
  }
}

export const bulkDuplicateCompliance = (data) => {
  return {
    type: "BULK_DUPLICATE_COMPLIANCE",
    data
  }
}

export const bulkDeleteCompliance = (complianceIds) => {
  return {
    type: "BULK_DELETE_COMPLIANCE",
    complianceIds
  }
}

export const setComplianceTotalData = (payload) => {
  return {
    type: "SET_COMPLIANCE_TOTAL_DATA",
    payload
  }
}

export const confirmCompliance = (data) => {
  return {
    type: "CONFIRM_COMPLIANCE",
    data
  }
}

export const getComplianceDetailChanges = (data) => {
  return {
    type: "GET_COMPLIANCE_DETAIL_CHANGES",
    data
  }
}

export const setLoadingConfirmCompliance = (payload) => {
  return {
    type: "SET_LOADING_CONFIRM_COMPLIANCE",
    payload,
  }
}

export const setComplianceDetailChanges = (payload) => {
  return {
    type: "SET_COMPLIANCE_DETAIL_CHANGES",
    payload
  }
}

export const setConfirmComplianceResponse = (payload) => {
  return {
    type: "SET_CONFIRM_COMPLIANCE_RESPONSE",
    payload,
  }
}

export const getComplianceMainInfoChanges = (comp_uuid) => {
  return {
    type: "GET_COMPLIANCE_MAIN_INFO_CHANGES",
    comp_uuid,
  }
}

export const setComplianceMainInfoChanges = (payload) => {
  return {
    type: "SET_COMPLIANCE_MAIN_INFO_CHANGES",
    payload
  }
}

export const getComplianceChecklistChanges = (comp_uuid) => {
  return {
    type: "GET_COMPLIANCE_CHECKLIST_CHANGES",
    comp_uuid
  }
}

export const setComplianceChecklistChanges = (payload) => {
  return {
    type: "SET_COMPLIANCE_CHECKLIST_CHANGES",
    payload
  }
}

export const getComplianceRegulationChanges = (comp_uuid) => {
  return {
    type: "GET_COMPLIANCE_REGULATION_CHANGES",
    comp_uuid,
  }
}

export const setComplianceRegulationChanges = (payload) => {
  return {
    type: "SET_COMPLIANCE_REGULATION_CHANGES",
    payload
  }
}