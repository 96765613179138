export const getDataDashboard = () => {
  return {
    type: "GET_DATA_DASHBOARD"
  }
}

export const setDataDashboard = (payload) => {
  return {
    type: "SET_DATA_DASHBOARD",
    payload
  }
}

export const getDashboardComplianceStatus = (params, category) => {
  return {
    type: "GET_DASHBOARD_COMPLIANCE_STATUS",
    params, category
  }
}

export const setDashboardComplianceStatus = (payload, category) => {
  return {
    type: "SET_DASHBOARD_COMPLIANCE_STATUS",
    payload, category
  }
}

export const getDashboardComplianceStatistics = (params, category) => {
  return {
    type: "GET_DASHBOARD_COMPLIANCE_STATISTICS",
    params, category
  }
}

export const setDashboardComplianceStatistics = (payload, category) => {
  return {
    type: "SET_DASHBOARD_COMPLIANCE_STATISTICS",
    payload, category
  }
}

export const getReportDashboard = (params) => {
  return {
    type: "GET_REPORT_DASHBOARD",
    params
  }
}


export const getDashboardGroup = (params) => {
  return {
    type: "GET_DASHBOARD_GROUP",
    params
  }
}

export const setDashboardGroup = (payload) => {
  return {
    type: "SET_DASHBOARD_GROUP",
    payload
  }
}


export const searchDashboardGroup = (params) => {
  return {
    type: "SEARCH_DASHBOARD_GROUP",
    params
  }
}

export const setSearchDashboardGroup = (payload) => {
  return {
    type: "SET_SEARCH_DASHBOARD_GROUP",
    payload
  }
}